import home from './home';
import verify from './verify';
import providers from './providers';
import reports from './reports';

export default [
    ...home,
    ...verify,
    ...providers,
    ...reports
];