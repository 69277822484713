export default [{
    header: 'Reportes',
    icon: 'PieChartIcon',
    children: [
        {
            title: 'Fondo de Garantía',
            route: 'reports-guarantee-fund',
            icon: 'ListIcon',
            action: 'read',
		    resource: 'reports'
        }
    ]    
}]