<template>
	<div class="navbar-header d-xl-block d-none">
		<ul class="nav navbar-nav">
			<li class="nav-item">
				<b-link
					class="navbar-brand"
					to="/"
				>
					<span class="brand-logo">            
						<b-img
							:src="appLogoImage"
							alt="logo"
							width="150"
						/>           
					</span>
				
				</b-link>
			</li>
		</ul>
	</div>
</template>

<script>

	import { $themeConfig } from '@themeConfig';

	export default {
		setup() {
			const { appLogoImage } = $themeConfig.app
			return {
				appLogoImage
			}
		}
	}

</script>